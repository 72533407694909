import React from 'react';
import Layout from '../components/Layout';
import { TagsComponent } from '../components/SideMenu/Tags';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

const TagsRoute: React.FC = (props) => {
  const { title } = props.data.site.siteMetadata;
  return (
    <Layout>
      <Helmet title={`Tags - ${title}`} />
      <TagsComponent />
    </Layout>
  );
};

export default TagsRoute;

export const pageQuery = graphql`
  query TagsRouteRouteQuery {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
  }
`;
